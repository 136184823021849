
import { defineComponent, ref } from 'vue';
import Multiselect from '@vueform/multiselect';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import router from '@/router';
import { generalStore } from '@/store';
import { BusinessClientsFilter } from '@/models/BusinessClients';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: {
        Multiselect,
        CheckBoxInput
    },
    name: 'BusinessClientsFilter',
    async setup() {
        const { t } = useI18n();
        const filter = ref<BusinessClientsFilter>(new BusinessClientsFilter());
        const filterCopy = generalStore.getters.getBusinessClientsFilter || new BusinessClientsFilter();
        filter.value = JSON.parse(JSON.stringify(filterCopy));
        const businessClientTypes = await generalStore.dispatch('getBusinessClientTypes');
        const businessClientTypesTranslations = businessClientTypes.map((x: any) => {
            return {
                id: x.id,
                text: t('businessClientTypes.' + x.text.replace(' ', ''))
            };
        });
        function ResetFilter() {
            generalStore.commit('updateBusinessClientsFilter', new BusinessClientsFilter());
            router.push({ name: 'businessClients' });
        }

        function Close() {
            router.push({ name: 'businessClients' });
        }

        function Search() {
            filter.value.keywords = '';
            generalStore.commit('updateBusinessClientsFilter', filter.value);
            router.push({ name: 'businessClients' });
        }

        return {
            filter,
            businessClientTypesTranslations,
            ResetFilter,
            Close,
            Search
        };
    }
});
